import React from "react";

function Testimonials() {
  return (
    <>
      <section id="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="section-title">
                <h2>
                  Student Reviews <small>from around the world</small>
                </h2>
              </div>

              <div className="owl-carousel owl-theme owl-client">
                <div className="col-md-4 col-sm-4">
                  <div className="item">
                    <div className="tst-image">
                      {/* <img
                        src="images/tst-image1.jpg"
                        className="img-responsive"
                        alt=""
                      /> */}
                    </div>
                    <div className="tst-author">
                      <h4>Jackson</h4>
                      <span>Developer</span>
                    </div>
                    <p>
                      You really do help young creative minds to get quality
                      education and professional job search assistance. I’d
                      recommend it to everyone!
                    </p>
                    <div className="tst-rating">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-4">
                  <div className="item">
                    <div className="tst-image">
                      {/* <img
                        src="images/tst-image2.jpg"
                        className="img-responsive"
                        alt=""
                      /> */}
                    </div>
                    <div className="tst-author">
                      <h4>Camila</h4>
                      <span>Marketing Manager</span>
                    </div>
                    <p>
                      Trying something new is exciting! Thanks for the amazing
                      course and the great teacher who was able to make it
                      interesting.
                    </p>
                    <div className="tst-rating">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-4">
                  <div className="item">
                    <div className="tst-image">
                      {/* <img
                        src="images/tst-image3.jpg"
                        className="img-responsive"
                        alt=""
                      /> */}
                    </div>
                    <div className="tst-author">
                      <h4>Barbie</h4>
                      <span>Art Director</span>
                    </div>
                    <p>
                      Many of my coworkers choose to use sturdywings for
                      continuing education. I feel it has the best selection,
                      training and curriculum vs others I have tried.
                    </p>
                    <div className="tst-rating">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
