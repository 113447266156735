import React from "react";
import SVG from "react-inlinesvg";

function Nav() {
  return (
    <>
      <section
        className="navbar custom-navbar navbar-fixed-top"
        role="navigation"
      >
        <div className="container">
          <div className="navbar-header">
            <button
              className="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span className="icon icon-bar"></span>
              <span className="icon icon-bar"></span>
              <span className="icon icon-bar"></span>
            </button>

            {/* lOGO TEXT HERE */}
            {/* <div className="logoImage"> */}
            <a href="/">
              <SVG
                src={"./images/sturdy_wings.svg"}
                // className="h-50 align-self-center"
              ></SVG>
            </a>
            {/* <img src={require("./images/sturdy_wings.svg")} alt="" /> */}
            {/* </div> */}
            {/* <a href="#" className="navbar-brand">Sturdy Wings</a> */}
          </div>

          {/* MENU LINKS */}
          <div className="collapse navbar-collapse">
            <ul className="nav navbar-nav navbar-nav-first">
              <li>
                <a href="#top" className="smoothScroll">
                  Home
                </a>
              </li>
              <li>
                <a href="#about" className="smoothScroll">
                  About
                </a>
              </li>
              <li>
                <a href="#team" className="smoothScroll">
                  Our Team
                </a>
              </li>
              <li>
                <a href="#courses" className="smoothScroll">
                  Courses
                </a>
              </li>
              <li>
                <a href="#testimonial" className="smoothScroll">
                  Reviews
                </a>
              </li>
              <li>
                <a href="#contact" className="smoothScroll">
                  Contact
                </a>
              </li>
            </ul>

            <ul className="nav navbar-nav navbar-right">
              <li>
                <span href="">
                  <i className="fa fa-phone"></i>+234 7018078486
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Nav;
