import React, { useState, useEffect } from "react";
import queryString from "query-string";
import "./enrollStyle.css";
import data from "../data/courses.json";
import { PaystackPayment } from "./PaystackPayment";

function EnrollForm() {
  const [sideImage, setSideImage] = useState("images/contact-image.jpg");
  const [courseValue, setCourseValue] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [expectations, setExpectations] = useState("");

  const handleCourseChange = (e) => {
    let imgUrl =
      e.target.options[e.target.selectedIndex].getAttribute("data-image");
    if (e.target.value !== "") {
      setCourseValue(e.target.value);
      setSideImage(imgUrl);
    } else {
      setSideImage("images/contact-image.jpg");
      setCourseValue("");
    }
  };
  useEffect(() => {
    const value = queryString.parse(window.location.search);
    const id = value.id;

    if (id) {
      let _image = data.find((ele) => ele.id === id);
      setSideImage(_image.courseImg);
      setCourseValue(_image.id);
    }
  });
  return (
    <section id="enrollForm">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <form id="enrollForm-form" action="" method="post">
              <div className="section-title">
                <h2>
                  {/* Contact us <small>we love conversations. let us talk!</small> */}
                  Select a Course{" "}
                  <small>Upgrade your skills with newest courses!</small>
                </h2>
              </div>

              <div className="col-md-12 col-sm-12">
                <select
                  className="form-control"
                  onChange={(e) => handleCourseChange(e)}
                  value={courseValue}
                >
                  <option value={""}>Select course</option>
                  {data.map((ele) => (
                    <option
                      value={ele.title}
                      data-image={ele.courseImg}
                      key={ele.id}
                    >
                      {ele.title}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  className="form-control is-invalid"
                  placeholder="Enter full name"
                  name="name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />

                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email address"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter phone number"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />

                <textarea
                  className="form-control"
                  rows="6"
                  placeholder="Expectations from course"
                  name="message"
                  value={expectations}
                  onChange={(e) => setExpectations(e.target.value)}
                ></textarea>
              </div>

              {/* // "pk_test_34e34f7f7cbd7e8c629cacb5a3b5ad1c0a42cddc" */}
              {/* // "pk_live_674422e34a90785c2a25729cbe8aad426a1757e8" */}
              <div className="col-md-4 col-sm-12">
                <PaystackPayment
                  prefix="Enrollment-"
                  paystack_public_key={
                    "pk_live_674422e34a90785c2a25729cbe8aad426a1757e8"
                  }
                  displayText={"Enroll Now"}
                  className="form-control"
                  courseValue={courseValue}
                  fullName={fullName}
                  email={email}
                  phone={phone}
                  expectations={expectations}
                />
                {/* <input
                  type="submit"
                  className="form-control"
                  id="footer_send_message"
                  name="send message"
                  value="Enroll Now"
                /> */}
              </div>
            </form>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="contact-image">
              <img src={sideImage} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EnrollForm;
