import axios from "axios";
import React from "react";
import { usePaystackPayment } from "react-paystack";

export function PaystackPayment(props) {
  const sendEmailPayload = (reference) => {
    axios
      .post("https://itb-professionals-be.herokuapp.com/api/jobs/apply", {
        fullName: props.fullName,
        email: props.email,
        phone: props.phone,
        course: props.courseValue,
        expectations: props.expectations,
        amount: "10,000",
        requestFrom: "sturdy@2022",
        requestFromType: "Enrol",
      })
      .then((result) => {
        window.location.replace("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let config = {
    reference: props.prefix + new Date().getTime(),
    email: props.email,
    amount: 10 * 100000,
    publicKey: props.paystack_public_key,
    currency: "NGN",
  };
  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // props.processPayment(reference, props.data)
    sendEmailPayload(reference);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config);
    return (
      <>
        <input
          onClick={(e) => {
            e.preventDefault();
            // props.setLoading(true);
            initializePayment(onSuccess, onClose);
          }}
          type="submit"
          className="form-control"
          id="footer_send_message"
          value="Enroll Now"
          disabled={
            props.courseValue == "" ||
            props.email == "" ||
            props.fullName == "" ||
            props.phone == ""
          }
        />
        {/* <span
          onClick={() => {
            // props.setLoading(true);
            initializePayment(onSuccess, onClose);
          }}
          className={props.className}
          //   disabled={props.loading}
        > */}
        {/* {props.displayText} */}
        {/* {props.loading && (
						<div
							className='spinner-border spinner-border-sm ml-2'
							role='status'
						>
							<span className='sr-only'>Loading...</span>
						</div>
					)} */}
        {/* </span> */}
      </>
    );
  };
  return <PaystackHookExample />;
}
