import React from "react";

function About() {
  return (
    <>
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="about-info">
                <h2>
                  Start your journey to a better life with online practical
                  courses
                </h2>

                <figure>
                  <span>
                    <i className="fa fa-users"></i>
                  </span>
                  <figcaption>
                    <h3>Professional Trainers</h3>
                    <p>
                      Learn from industry professionals and experts across all
                      fields.
                    </p>
                  </figcaption>
                </figure>

                <figure>
                  <span>
                    <i className="fa fa-certificate"></i>
                  </span>
                  <figcaption>
                    <h3>International Certifications</h3>
                    <p>
                      Demonstrate your commitment to superior professionalism,
                      upholding industry standards, and continued learning.
                    </p>
                  </figcaption>
                </figure>

                <figure>
                  <span>
                    <i className="fa fa-bar-chart-o"></i>
                  </span>
                  <figcaption>
                    <h3>Access to Mentorship</h3>
                    <p>
                      {" "}
                      Get feedbacks and directions from mentors available to
                      assist.
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-md-offset-1 col-md-4 col-sm-12">
              <div className="entry-form">
                {/* <form>
                  <h2>Register today</h2>
                  <input
                    type="text"
                    name="full name"
                    className="form-control"
                    placeholder="Full name"
                    required=""
                  />

                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Your email address"
                    required=""
                  />

                  <button className="submit-btn form-control" id="form-submit">
                    Get started
                  </button>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
