import React from "react";

function FeatureCard({ number, title, body }) {
  return (
    <div className="col-md-4 col-sm-4">
      <div className="feature-thumb">
        <span>{number}</span>
        <h3>{title}</h3>
        <p>{body}</p>
      </div>
    </div>
  );
}

export default FeatureCard;
