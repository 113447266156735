import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PaystackPayment } from "../../pages/PaystackPayment";
import CourseModal from "./CourseModal";

function CourseCard({
  courseImg,
  duration,
  title,
  description,
  tutorImg,
  tutorName,
  price,
  id,
}) {
  return (
    <>
      {/* <CourseModal handleClose={handleClose} /> */}
      <div className="col-md-4 col-sm-4">
        <div className="item">
          <div className="courses-thumb">
            <div className="courses-top">
              <div className="courses-image">
                <img src={courseImg} className="img-responsive" alt="" />
              </div>
              <div className="courses-date">
                {/* <span><i className="fa fa-calendar"></i> 12 / 7 / 2018</span> */}
                <span>
                  <i className="fa fa-clock-o"></i> {duration} hours
                </span>
              </div>
            </div>

            <div
              className="courses-detail"
              style={{
                maxHeight: "230px",
                minHeight: "230px",
              }}
            >
              <h3>
                <span
                // href="https://forms.gle/eAgfVkfW3AanuTGN9"
                // target="_blank"
                >
                  {title}
                </span>
              </h3>
              <p>{description}</p>
              <br />
            </div>

            <div className="courses-info">
              <div className="courses-author">
                <img src={tutorImg} className="img-responsive" alt="" />
                <span>{tutorName}</span>
              </div>
              {/* <PaystackPayment
                  prefix="Enrollment-"
                  paystack_public_key={
                    "pk_test_34e34f7f7cbd7e8c629cacb5a3b5ad1c0a42cddc"
                  }
                  displayText={"Enroll"}
                  className=""
                /> */}
              <div className="courses-price">
                {/* <PaystackPayment
                  prefix="Enrollment-"
                  paystack_public_key={
                    "pk_test_34e34f7f7cbd7e8c629cacb5a3b5ad1c0a42cddc"
                  }
                  displayText={"Enroll"}
                  className=""
                /> */}
                <Link to={`/enroll?id=${id}`}>
                  {" "}
                  <span>Enroll</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseCard;
