import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

function Contact() {
  const notify = (code, message) => toast[code](message);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  return (
    <section id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <form id="contact-form" action="" method="post">
              <div className="section-title">
                <h2>
                  Contact us <small>we love conversations. let us talk!</small>
                </h2>
              </div>

              <div className="col-md-12 col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter full name"
                  name="name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />

                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email address"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <textarea
                  className="form-control"
                  rows="6"
                  placeholder="Tell us about your message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>

              <div className="col-md-4 col-sm-12">
                <input
                  type="submit"
                  className="form-control"
                  id="footer_send_message"
                  name="send message"
                  value="Send Message"
                  disabled={
                    fullName == "" || !validateEmail(email) || message == ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (fullName && message && validateEmail(email)) {
                      axios
                        .post(
                          "https://itb-professionals-be.herokuapp.com/api/jobs/apply",
                          {
                            fullName,
                            message,
                            email,
                            requestFrom: "sturdy@2022",
                            requestFromType: "Contact",
                          }
                        )
                        .then((result) => {
                          if (result.data.status) {
                            notify(
                              "success",
                              "Thanks for contacting us, we'll get back to you soon!"
                            );
                            setEmail("");
                            setFullName("");
                            setMessage("");
                          } else {
                            notify(
                              "error",
                              "Please check your inputs and try again"
                            );
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  }}
                />
              </div>
            </form>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="contact-image">
              <img
                src="images/contact-image.jpg"
                className="img-responsive"
                alt="Smiling Two Girls"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
