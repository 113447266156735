import React from "react";
import { PaystackPayment } from "../pages/PaystackPayment";
import data from "../data/courses.json";
import CourseCard from "./components/CourseCard";

function Courses() {
  return (
    <>
      <section id="courses">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="section-title">
                <h2>
                  Courses We offer{" "}
                  <small>Upgrade your skills with newest courses</small>
                </h2>
              </div>

              <div className="owl-carousel owl-theme owl-courses">
                {data.map((ele, index) => (
                  <CourseCard
                    key={index}
                    courseImg={ele.courseImg}
                    duration={ele.duration}
                    title={ele.title}
                    description={ele.description}
                    tutorImg={ele.tutorImg}
                    tutorName={ele.tutorName}
                    id={ele.id}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Courses;
