import React from "react";
import FeatureCard from "./components/FeatureCard";

function Feature() {
  return (
    <>
      <section id="feature">
        <div className="container">
          <div className="row">
            <FeatureCard
              number={"09"}
              title={"Trending Courses"}
              body={
                "There's Nothing Better Than Getting Better. Keep Growing WithUnlimited Creative Classes."
              }
            />
            <FeatureCard
              number={"16"}
              title={"Books & Library"}
              body={
                "Explore new skills, deepen existing passions, and get lost in creativity. What you find would surprise and inspire you"
              }
            />
            <FeatureCard
              number={"07"}
              title={"Certified Teachers"}
              body={
                "Our teachers are experts and industry rock stars excited to share their experience, wisdom, and tools with you."
              }
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Feature;
