import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Enroll from "./pages/Enroll";
export default function BasePage() {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/enroll" component={Enroll} />
      <Redirect to="error" />
    </Switch>
  );
}
