import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "../app/Routes";

const { PUBLIC_URL } = process.env;

function App() {
  console.log(PUBLIC_URL);
  return (
    <>
      {/* Override `basename` (e.g: `homepage` in `package.json`) */}
      <BrowserRouter basename={PUBLIC_URL}>
        {/* Render routes with provided `Layout`. */}
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;
