import React from "react";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <>
      <section id="home">
        <div className="row">
          <div className="owl-carousel owl-theme home-slider">
            <div className="item item-first">
              <div className="caption">
                <div className="container">
                  <div className="col-md-6 col-sm-12">
                    <h1>Start your journey with our practical courses</h1>
                    <h3>
                      Our online courses are designed to fit in your industry
                      supporting all-round with latest technologies.
                    </h3>
                    <Link
                      to={"/enroll"}
                      // href="https://forms.gle/eAgfVkfW3AanuTGN9"
                      // rel="noreferrer"
                      className="section-btn btn btn-default smoothScroll"
                      // target="_blank"
                    >
                      Take a course
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="item item-second">
              <div className="caption">
                <div className="container">
                  <div className="col-md-6 col-sm-12">
                    <h1>Distance Learning Education Center</h1>
                    <h3>
                      Our online courses are built in partnership with
                      technology leaders and are designed to meet industry
                      demands.
                    </h3>
                    <Link
                      to={"/enroll"}
                      // href="https://forms.gle/eAgfVkfW3AanuTGN9"
                      // rel="noreferrer"
                      className="section-btn btn btn-default smoothScroll"
                      // target="_blank"
                    >
                      Take a course
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="item item-third">
              <div className="caption">
                <div className="container">
                  <div className="col-md-6 col-sm-12">
                    <h1>Efficient Learning Methods</h1>
                    <h3>
                      Our online courses are built in partnership with
                      technology leaders and are designed to meet industry
                      demands.
                    </h3>
                    <Link
                      to={"/enroll"}
                      // href="https://forms.gle/eAgfVkfW3AanuTGN9"
                      // rel="noreferrer"
                      className="section-btn btn btn-default smoothScroll"
                      // target="_blank"
                    >
                      Take a course
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
