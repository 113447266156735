import React from "react";
import { ToastContainer } from "react-toastify";
import About from "../modules/About";
import Contact from "../modules/Contact";
import Courses from "../modules/Courses";
import Feature from "../modules/Feature";
import Footer from "../modules/Footer";
import Hero from "../modules/Hero";
import Nav from "../modules/Nav";
import Testimonials from "../modules/Testimonials";

function HomePage() {
  return (
    <>
      <ToastContainer />
      {/* <!-- PRE LOADER --> */}
      <section className="preloader">
        <div className="spinner">
          <span className="spinner-rotate"></span>
        </div>
      </section>
      <Nav />
      <Hero />
      <Feature />
      <About />
      <Courses />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
}

export default HomePage;
