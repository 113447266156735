import React from "react";

function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6">
            {/* <div className="footer-info">
                            <div className="section-title">
                                 <h2>Headquarter</h2>
                            </div>
                            <address>
                                 <p>1800 dapibus a tortor pretium,<br> Integer nisl dui, ABC 12000</p>
                            </address>

                            <ul className="social-icon">
                                 <li><a href="#" className="fa fa-facebook-square" attr="facebook icon"></a></li>
                                 <li><a href="#" className="fa fa-twitter"></a></li>
                                 <li><a href="#" className="fa fa-instagram"></a></li>
                            </ul>

                            <div className="copyright-text">
                                 <p>Copyright &copy;
                                      <script>document.write(new Date().getFullYear())</script> SturdyWings Education
                                 </p>


                            </div>
                       </div> */}
            <div className="footer-info">
              <div className="section-title">
                <h2>Contact Info</h2>
              </div>
              <address>
                <p>+1 267-652-8662, +234 7018078486</p>
                <p>
                  <a href="mailto:admin@sturdywingsedu.com">
                    admin@sturdywingsedu.com
                  </a>
                </p>
              </address>
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            {/* <div className="footer-info">
                            <div className="section-title">
                                 <h2>Contact Info</h2>
                            </div>
                            <address>
                                 <p>+1 267-652-8662, +234 7018078486</p>
                                 <p><a href="mailto:admin@sturdywingsedu.com">admin@sturdywingsedu.com</a></p>
                            </address>
   
                       </div> */}
          </div>

          <div className="col-md-4 col-sm-12">
            <div className="footer-info newsletter-form">
              <div className="section-title">
                <h2>Newsletter Signup</h2>
              </div>
              <div>
                <div className="form-group">
                  <form>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email"
                      name="email"
                      id="email"
                      required=""
                    />
                    <input
                      type="submit"
                      className="form-control"
                      name="submit"
                      id="form-submit_1"
                      value="Sign up"
                    />
                  </form>
                  <span>
                    <sup>*</sup> Please note - we do not spam your email.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
