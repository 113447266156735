import React from "react";
import Contact from "../modules/Contact";
import Footer from "../modules/Footer";
import Hero from "../modules/Hero";
import Nav from "../modules/Nav";
import EnrollForm from "./EnrollForm";

function Enroll() {
  return (
    <>
      {/* <!-- PRE LOADER --> */}
      {/* <section className="preloader">
        <div className="spinner">
          <span className="spinner-rotate"></span>
        </div>
      </section> */}
      <Nav />
      {/* <Hero /> */}
      {/* <Feature />
    <About />
    <Courses />
    <Testimonials /> */}
      <EnrollForm />
      <Footer />
    </>
  );
}

export default Enroll;
